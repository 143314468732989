import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const LegalPage = ({ data }) => {
  return (
    <Layout header="nomenu">
      <div className="no-masthead relative"></div>

      <section className="section sectionFirst legal-disclaimer">
        <div className="container py-10">
          <h1 className="text-lg md:text-2xl xl:text-3xl leading-tight uppercase font-light mb-5">
            LEGAL NOTICE AND WEBSITE USE.
          </h1>
          <p className="mb-5">
            Eurokars Auto Pte. Ltd. and its related companies (“the Eurokars
            Group” or “we”) appreciates your interest in our products and your
            visit to our website (“website”). By using and/or accessing this
            website, you agree to and accept the terms and conditions in this
            Legal Notice as well as the Eurokars Group’s Data Protection Policy.
            We may vary these terms and conditions at any time without notice
            and your continued use and/or access of this website constitutes
            your agreement to and acceptance of the varied terms and conditions.
            If you do not accept these terms and conditions, please do not use
            this website. This website is for your personal use only and is not
            to be used for any commercial purpose.
          </p>
          <h2 className="text-lg md:text-2xl xl:text-3xl leading-tight uppercase font-light mb-5">
            WEBSITE TERMS.
          </h2>
          <p className="mb-3">
            The terms below govern your use of this website and by accessing
            this website you agree to be bound by them. If you do not accept
            these terms, please do not use this website.
          </p>
          <p className="mb-3">
            We may change these terms at any time without notice by updating the
            terms shown on this website. It is your responsibility to review
            these terms each time you enter the website to ensure you are aware
            of our latest terms and conditions. Your use of this website after a
            change has been made signifies your acceptance of the revised terms.
          </p>
          <p className="mb-3">
            This website is made available free of charge and for personal use
            only and is not to be used for any commercial purpose.
          </p>
          <h2 className="text-lg md:text-2xl xl:text-3xl leading-tight uppercase font-light mb-5">
            PRODUCTS AND SERVICES.
          </h2>
          <p>
            The provision of details of any products and services on this
            website are not, and should not be construed as, an offer to sell or
            supply such products or services, and the seller or supplier may
            always accept or reject your offer at its sole discretion.
          </p>
          <p>
            All products and services on this website are subject to
            availability and may be withdrawn without notice. All products and
            services and all prices are also subject to change without notice.
          </p>
          <h2 className="text-lg md:text-2xl xl:text-3xl leading-tight uppercase font-light mb-5">
            TERMS AND CONDITIONS FOR INDIVIDUAL PRODUCTS AND SERVICES.
          </h2>
          <p>
            These terms should be read in conjunction with the separate terms
            and conditions for the sale or supply of the individual products or
            services which are referred to on this website. In the event of any
            inconsistency between these terms and the specific product or
            service terms and conditions, the latter shall prevail.
          </p>
          <h2 className="text-lg md:text-2xl xl:text-3xl leading-tight uppercase font-light mb-5">
            INFORMATION ABOUT PRODUCTS AND SERVICES.
          </h2>
          <p>
            Whilst we have exercised best efforts to ensure the information on
            this website is up to date and accurate, neither we nor any BMW
            group company accept responsibility for any claims, damages, losses,
            expenses, costs or liabilities whatsoever arising from your reliance
            on any information on this website.
          </p>
          <p>
            Please check directly with our authorised sales consultants for
            model availability and for precise information on vehicle model
            features, specifications and equipment, and in the case of used
            vehicles, current mileage details before ordering a vehicle.
          </p>
          <p>
            Always check the terms on which any product or service is supplied
            before making any commitment. Copies of vehicle finance agreements
            and detailed insurance policies and summaries of cover and other
            product or service terms are also available from us on request.
          </p>
          <p>
            You should seek your own independent legal and financial advice in
            relation to any legal, tax or accounting matters referred to on this
            website.
          </p>
          <h2 className="text-lg md:text-2xl xl:text-3xl leading-tight uppercase font-light mb-5">
            COPYRIGHT.
          </h2>
          <p>
            The copyright in the material contained in this website belongs to
            the Eurokars Group, a BMW group company or its ultimate parent, BMW
            AG, or their licensors. No person may copy, modify, transmit,
            distribute, display, reproduce, publish, license, or create works
            from any part of this website or otherwise use it for any public or
            commercial use without the prior express written permission of BMW.
            You may only view or print individual pages for your own personal
            use.
          </p>
          <h2 className="text-lg md:text-2xl xl:text-3xl leading-tight uppercase font-light mb-5">
            WARRANTIES, LIABILITY.
          </h2>
          <p>
            While the information, images, materials and other content provided
            in this Legal Notice and on this website is believed to be accurate,
            it may include errors or inaccuracies. We, any BMW group company and
            BMW AG are not responsible for the contents of websites that are
            maintained by third parties and are not liable for their contents.
            The information on this website is provided "as is" and all
            warranties, conditions and other terms implied by statute or common
            law are, to the fullest extent permitted by law, excluded.
          </p>
          <p>
            Nothing in this agreement excludes our liability to you (a) for
            death or personal injury caused by our negligence; or (b) for fraud
            or fraudulent misrepresentation. Subject to this, in no event shall
            we, any BMW group company or BMW AG be liable to any person for any
            loss of profits, loss of business, depletion of goodwill or similar
            losses or pure economic loss, or for any special, indirect or
            consequential loss costs, damages, charges or expenses however
            arising, unless caused by gross negligence or intentional
            misconduct.
          </p>
          <p>
            Under this notice, "our liability" includes that of any company in
            the Eurokars Group and their respective agents, employees,
            contributors and consultants and sub-contractors, "you" includes any
            other party claiming through you and "loss or damage" includes any
            losses, damages, costs or expenses whatsoever or howsoever arising
            in connection with the products and services we provide including
            those listed on this website or in this Legal Notice, whether under
            this agreement or other agreement or in consequence of any
            misrepresentation, misstatement or tortious act or omission,
            including negligence.
          </p>
          <h2 className="text-lg md:text-2xl xl:text-3xl leading-tight uppercase font-light mb-5">
            TRADEMARKS.
          </h2>
          <p>
            The names, logos and images on this website which identify any
            Eurokars Group company, BMW group company, or third parties and
            their products and services are proprietary marks of the Eurokars
            Group, BMW and/or the relevant third parties. Nothing contained in
            this website shall be deemed to confer on any person any license or
            right on the part of the Eurokars Group, BMW or any third party with
            respect to any such name, logo, or image.
          </p>
          <h2 className="text-lg md:text-2xl xl:text-3xl leading-tight uppercase font-light mb-5">
            LICENCES.
          </h2>
          <p>
            We have sought to achieve an innovative and informative website.
            However, no license to use the intellectual property of the Eurokars
            Group, the BMW group or third parties has been granted by this
            website.
          </p>
          <h2 className="text-lg md:text-2xl xl:text-3xl leading-tight uppercase font-light mb-5">
            LINKS TO OTHER WEBSITES.
          </h2>
          <p>
            This site may contain (hypertext) links to other sites that enable
            users to leave this site and go directly to the linked site. The
            links are provided to assist users of this website and the inclusion
            of a link does not imply that we endorse or have approved the linked
            site. We do not control linked sites and are not responsible or
            liable for their content or any link within such sites, or for any
            transmission received from any linked sites.
          </p>
          <h2 className="text-lg md:text-2xl xl:text-3xl leading-tight uppercase font-light mb-5">
            SEVERANCE.
          </h2>
          <p>
            If any of these terms are found to be illegal, invalid, or otherwise
            unenforceable, then to the extent of such illegality, invalidity or
            unenforceability, such terms shall be deleted and severed from the
            website terms. The remaining enforceable terms shall continue in
            full force and effect and continue to be binding.
          </p>
          <p>
            Nothing in these terms shall exclude or limit our liability for
            death or personal injury resulting from our negligence or shall
            affect your statutory rights if you are a consumer.
          </p>
          <h2 className="text-lg md:text-2xl xl:text-3xl leading-tight uppercase font-light mb-5">
            JURISDICTION.
          </h2>
          <p>
            These terms shall be governed by and construed in accordance with
            Singaporean law. Disputes arising in relation to them shall, unless
            expressly agreed otherwise, be subject to the exclusive jurisdiction
            of the relevant courts of the Republic of Singapore.
          </p>
          <h2 className="text-lg md:text-2xl xl:text-3xl leading-tight uppercase font-light mb-5">
            WEBSITE INFORMATION.
          </h2>
          <p>
            The contents of any digital showroom, online shop, promotional
            activities, products, and services that may be provided on this
            website, as well as any live chat function, are provided by us or
            our trusted selected partners, service providers and agents, as the
            case may be. They should not be construed as an offer to sell or
            supply products or services, and the we may at our sole discretion
            accept or reject your offer. All products and services on this
            website are subject to availability, eligibility and additional
            terms and conditions available from us upon request. They may be
            changed or withdrawn without notice.
          </p>
          <h2 className="text-lg md:text-2xl xl:text-3xl leading-tight uppercase font-light mb-5">
            USE OF SOCIAL MEDIA PLUGINS.
          </h2>
          <p>
            One of the features that may be made available to you is the use of
            plugins from social media such as Facebook, Instagram, YouTube,
            LinkedIn, or Twitter, etc. which are operated by the corresponding
            social media provider. When you access this website, your browser
            may establish a direct connection with the respective social media
            servers. The content of the plugins is transferred by the relevant
            social media directly to your browser, which is then integrated into
            the website. Integration of the plugins enables social media to
            receive the information you have accessed on this website. If you do
            not want social media to gather data about you via this website, you
            must log out of those social media before visiting this website. You
            should also peruse the privacy policies of those social media to
            understand the collection and usage of your data by them.
          </p>
        </div>
      </section>
    </Layout>
  )
}

export default LegalPage

const seoDetails = {
  title: "Eurokars Auto | Legal Disclaimer",
  description: "Legal Notice and Website Use.",
  keywords: "Legal Disclaimer",
  image: "og-eka-legal-disclaimer.jpg",
}
export const Head = ({ data }) => (
  <>
    <title>{seoDetails.title}</title>
    <meta property="og:type" content="website" />
    <meta
      name="description"
      property="description"
      content={seoDetails.description}
    ></meta>
    <meta
      name="og:description"
      property="og:description"
      content={seoDetails.description}
    ></meta>
    <meta
      name="keywords"
      property="keywords"
      content={seoDetails.keywords}
    ></meta>
    <meta
      name="og:url"
      property="og:url"
      content={`${process.env.GATSBY_BASE_URL}`}
    ></meta>
    <meta
      name="og:image"
      property="og:image"
      content={`${process.env.GATSBY_BASE_URL}/img/${seoDetails.image}`}
    ></meta>

    <script
      async
      src="https://www.googletagmanager.com/gtag/js?id=AW-782099393"
    ></script>
    <script
      async
      src="https://www.googletagmanager.com/gtag/js?id=G-8FJZCNFV3R"
    ></script>
    <script
      async
      src="https://www.googletagmanager.com/gtag/js?id=GTM-M7DKTD9"
    ></script>
  </>
)
